import { Button, Card } from '@pesto/ui';
import { DropdownInput } from '@pesto/ui/components/DropdownInput';
import { QrCode } from '@pesto/ui/components/QrCode';
import { formatApplicationValues, humanize } from '@pesto/utils';
import { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import type { AssetType, CreditApplication } from '../../../__generated__/graphql/api';
import { CreditApplicationStatus, useAddBasicItemMutation } from '../../../__generated__/graphql/api';
import { routes } from '../../../constants/routes';
import { addBasicItemsOptions } from '../../CreditApplicationDetail/CreditApplicationDetail';

export function ApplicationCard({ application, index }: { application?: CreditApplication | null; index: number }) {
  const status = application?.status ?? CreditApplicationStatus.InvalidState;
  const navigate = useNavigate();
  const [newBasicItem, setNewBasicItem] = useState<AssetType>();
  const [addBasicItem] = useAddBasicItemMutation();

  const displayNameOrEmail =
    status === CreditApplicationStatus.PrequalItemValued
      ? application?.pestoUser?.email
      : `${application?.pestoUser?.firstName} ${application?.pestoUser?.lastName}`;

  const handleAddBasicItem = useCallback(async () => {
    if (application?.id && newBasicItem) {
      await addBasicItem({
        variables: {
          creditApplicationId: application?.id,
          assetType: newBasicItem,
        },
      });

      setNewBasicItem(undefined);
    }
  }, [newBasicItem, addBasicItem, application]);

  return (
    <Card key={`application:${index}`} className="bg-card overflow-x-auto overflow-y-visible sm:rounded-lg">
      <table className="text-foreground w-fit text-left text-sm">
        <tbody>
          <tr className="border-b">
            <th scope="row" className="whitespace-nowrap p-4 font-medium">
              Application #{index}
            </th>
            <td className="p-4 text-end">
              <Button
                color={'secondary'}
                onClick={() => {
                  navigate(`${routes.creditApplications}/${application?.id}`);
                }}
              >
                Manage
              </Button>
            </td>
          </tr>
          <tr className="border-b">
            <th scope="row" className="whitespace-nowrap px-4 py-4 font-medium">
              <QrCode data={application?.id ?? ''} size={70} />
            </th>
            <td className="px-2 py-4 text-end">{formatApplicationValues('id', application?.id)}</td>
          </tr>
          <tr className="border-b">
            <th scope="row" className="whitespace-nowrap p-4 font-medium">
              Status
            </th>
            <td className="p-4 text-end">{humanize(application?.status ?? '')}</td>
          </tr>
          <tr className="border-b">
            <th scope="row" className="whitespace-nowrap p-4 font-medium">
              User
            </th>
            <td className="p-4 text-end">
              <div className={'flex items-center justify-end'}>
                <p className={'mx-4'}>{displayNameOrEmail}</p>
                <QrCode data={application?.pestoUser?.lastName ?? ''} size={70} />
              </div>
            </td>
          </tr>
          <tr className="border-b">
            <th scope="row" className="whitespace-nowrap p-4 font-medium">
              Email
            </th>
            <td className="p-4 text-end">{application?.pestoUser?.email}</td>
          </tr>
          <tr className="border-b">
            <th scope="row" className="whitespace-nowrap p-4 font-medium">
              Address 1
            </th>
            <td className="p-4 text-end">{application?.pestoUser?.shippingAddress?.addressFirstLine}</td>
          </tr>
          <tr className="border-b">
            <th scope="row" className="whitespace-nowrap p-4 font-medium">
              Address 2
            </th>
            <td className="p-4 text-end">{application?.pestoUser?.shippingAddress?.addressSecondLine}</td>
          </tr>
          <tr className="border-b">
            <th scope="row" className="whitespace-nowrap p-4 font-medium">
              City
            </th>
            <td className="p-4 text-end">{application?.pestoUser?.shippingAddress?.city}</td>
          </tr>
          <tr className="border-b">
            <th scope="row" className="whitespace-nowrap p-4 font-medium">
              State
            </th>
            <td className="p-4 text-end">{application?.pestoUser?.shippingAddress?.state}</td>
          </tr>
          <tr className="border-b">
            <th scope="row" className="whitespace-nowrap p-4 font-medium">
              Zip
            </th>
            <td className="p-4 text-end">{application?.pestoUser?.shippingAddress?.zip}</td>
          </tr>
          {application?.items
            ?.filter(i => i?.active === true)
            .map((item: any, index: any) => {
              return (
                <tr key={item?.id} className="border-b">
                  <th scope="row" className="whitespace-nowrap p-4 font-medium">
                    <div>
                      <Link className={'text-action hover:text-actionSoft'} to={`${routes?.itemDetail}/${item?.id}`}>
                        Item {index}
                      </Link>
                      <QrCode data={item?.id ?? ''} size={70} />
                    </div>
                  </th>
                  <td className="p-4 text-end">
                    <div>
                      {humanize(item?.subtype?.trim() ?? 'New')} | {humanize(item?.type?.trim() ?? '')}
                      {item?.presignedPhotoLinks?.length && item?.presignedPhotoLinks?.length > 0 && (
                        <div key={item?.id} className="flex h-[86px] w-[64px] gap-2 rounded object-cover object-center">
                          {item?.presignedPhotoLinks?.map((url: any) => (
                            <img
                              key={url}
                              className={'w-full rounded object-cover object-center'}
                              src={url || ''}
                              alt={`Preview of ${item.type}`}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          <tr className="border-b">
            <th scope="row" className="whitespace-nowrap p-4 font-medium">
              Add Basic Item
            </th>
            <td className="justify-content-end p-4">
              <div className={'flex'}>
                <DropdownInput
                  className={'w-44'}
                  options={addBasicItemsOptions}
                  label={''}
                  value={newBasicItem}
                  onChange={v => setNewBasicItem(v as AssetType)}
                />
                <Button disabled={!newBasicItem} onClick={handleAddBasicItem}>
                  Add Item
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
}
