import { CreditCardIcon } from '@heroicons/react/20/solid';
import { useAuth } from '@pesto/features';
import type { ExpandableMenuList, HomeLink } from '@pesto/ui/components/Navigation/Navigation.types';
import { ApplicationLayout } from '@pesto/ui/layouts/ApplicationLayout';
import { useTheme } from '@pesto/ui/providers/ThemeProvider';
import { humanize } from '@pesto/utils/src/string/stringHelper';
import {
  ArrowLeftRight,
  Asterisk,
  CreditCard,
  Currency,
  Dna,
  Home,
  Package,
  Package2,
  Rocket,
  ShoppingCart,
  Users,
  Wrench,
} from 'lucide-react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { ColorSchemeValue } from 'partner-control/src/constants/themes';

import { useCurrentUserQuery } from '../__generated__/graphql/api';
import { routes } from '../constants/routes';

export interface ApplicationLayoutProps {
  className?: string;
  children?: React.ReactNode;
  component?: React.ReactNode;
}

export const MCApplicationLayout = (props: ApplicationLayoutProps) => {
  const { children } = props;
  const { signOut } = useAuth();
  const { data } = useCurrentUserQuery();
  const user = data?.currentUser;
  const { setTheme, colorScheme, setColorScheme, availableColorSchemes } = useTheme();

  const userMenuItems: ExpandableMenuList = [
    { type: 'label', label: user?.displayName || 'My Account' },
    { type: 'separator' },
    { type: 'item', label: 'Settings', onClick: () => console.log('Settings clicked') },
    { type: 'item', label: 'Support', onClick: () => console.log('Support clicked') },
    {
      type: 'sub',
      label: 'Theme',
      items: [
        { type: 'item', label: 'Light', onClick: () => setTheme('light') },
        { type: 'item', label: 'Dark', onClick: () => setTheme('dark') },
        { type: 'item', label: 'System', onClick: () => setTheme('system') },
      ],
    },
    {
      type: 'sub',
      label: 'Color Scheme',
      items: availableColorSchemes.map(scheme => ({
        type: 'item',
        label: humanize(scheme),
        onClick: () => setColorScheme(scheme),
      })),
    },
    { type: 'separator' },
    { type: 'item', label: 'Logout', onClick: () => signOut() },
  ];

  const menuLinks: ExpandableMenuList = [
    {
      type: 'item',
      href: routes.dashboard,
      label: 'Dashboard',
      icon: Home,
    },
    {
      type: 'item',
      href: routes.shipments,
      label: 'Shipments',
      icon: Package2,
    },
    {
      type: 'item',
      href: routes.creditApplications,
      label: 'Credit Applications',
      icon: ShoppingCart,
      count: 6,
    },
    {
      type: 'item',
      href: routes.payments,
      label: 'Payments',
      icon: Currency,
    },
    {
      type: 'item',
      href: routes.transactions,
      label: 'Transactions',
      icon: ArrowLeftRight,
    },
    {
      type: 'item',
      href: routes.items,
      label: 'Items',
      icon: Package,
    },
    {
      type: 'item',
      href: routes.users,
      label: 'Users',
      icon: Users,
    },
    {
      type: 'item',
      href: routes.partners,
      label: 'Partners',
      icon: Users,
    },
    {
      type: 'item',
      href: routes.cards,
      label: 'Cards',
      icon: CreditCard,
    },
    {
      type: 'item',
      href: routes.statements,
      label: 'Statements',
      icon: Wrench,
    },
    {
      type: 'item',
      href: routes.creditEngine,
      label: 'Credit Engine',
      icon: Dna,
    },
    {
      type: 'sub',
      label: 'Other',
      icon: Asterisk,
      items: [
        { type: 'item', label: 'Reports', href: routes.reports },
        { type: 'item', label: 'Legal Links', href: routes.legalLinks },
        { type: 'item', label: 'Looker Studio', href: routes.lookerStudio },
        { type: 'item', label: 'Security Events', href: routes.securityEvents },
        { type: 'item', label: 'Ad Tracking', href: routes.adTracking },
      ],
    },
  ];

  const homeLink: HomeLink = {
    name: 'Mission Control',
    href: routes.dashboard,
    icon: Rocket,
  };

  const homeLightLink: HomeLink = {
    name: 'The Admin Panel',
    href: routes.dashboard,
    icon: CreditCardIcon,
  };

  const logo: HomeLink = colorScheme === ColorSchemeValue.HOMELIGHT ? homeLightLink : homeLink;

  // change to !user
  const location = useLocation();
  if (location.pathname === '/') {
    return <>{children}</>;
  }

  return (
    <ApplicationLayout userMenuItems={userMenuItems} menuLinks={menuLinks} homeLink={logo}>
      {children}
    </ApplicationLayout>
  );
};
