import { useClipboard } from '@pesto/hooks';
import { cn } from '@pesto/utils';
import { CopyIcon } from 'lucide-react';
import type { ReactNode } from 'react';
import { useCallback } from 'react';

interface DetailRowProps {
  title: string;
  value: ReactNode;
  copyEnabled?: boolean;
  onCopy?: () => void;
}

export const DetailRow = (props: DetailRowProps) => {
  const { title, value, copyEnabled, onCopy } = props;
  const { copy } = useClipboard();

  const handleCopy = useCallback(() => {
    if (typeof value !== 'string') return;
    copy(value);
    onCopy && onCopy();
  }, [copy, onCopy, value]);

  return (
    <div className="text-primary/60 w-full whitespace-nowrap px-4 py-1 font-medium">
      {title}
      <div className={'flex'}>
        <div
          className={cn('text-foreground', {
            truncate: copyEnabled,
          })}
        >
          {value}
        </div>
        {copyEnabled && (
          <button onClick={handleCopy}>
            <CopyIcon />
          </button>
        )}
      </div>
    </div>
  );
};
