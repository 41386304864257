import { useClipboard } from '@pesto/hooks';
import { Button, Card, LoadingSpinner, Typography } from '@pesto/ui';
import { BackButton } from '@pesto/ui/components/BackButton';
import { Checkbox } from '@pesto/ui/components/Checkbox';
import { DropdownInput } from '@pesto/ui/components/DropdownInput';
import { TextAreaField } from '@pesto/ui/components/Forms/TextAreaField';
import { Modal } from '@pesto/ui/components/Modal';
import { QrCode } from '@pesto/ui/components/QrCode';
import { humanize } from '@pesto/utils';
import { CopyIcon } from 'lucide-react';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import type { CreditApplication } from '../../__generated__/graphql/api';
import {
  DamageRecordCategory,
  ShipmentStatus,
  ShipmentType,
  useAddDamageRecordShippingMutation,
  useMarkShippingKitSentForShipmentMutation,
  useShipmentFromTrackingNumberQuery,
  useUpdateShipmentTrackingMutation,
} from '../../__generated__/graphql/api';
import { MainWrapper } from '../../components/MainWrapper';
import { getTrackingLink } from '../../utils/getTrackingLink';

import { ApplicationCard } from './components/ApplicationCard';

export function ShipmentDetail() {
  const params = useParams();
  const trackingNumber = params?.id ?? '';

  const { data, loading } = useShipmentFromTrackingNumberQuery({
    variables: { trackingNumber },
    fetchPolicy: 'network-only',
  });
  const [addShippingDamageMutation, { loading: loadingDamage }] = useAddDamageRecordShippingMutation();
  const [updateShipmentTrackingMutation, { loading: loadingUpdateShipment }] = useUpdateShipmentTrackingMutation();
  const [markShippingKitSent] = useMarkShippingKitSentForShipmentMutation({
    variables: { shipmentId: data?.shipmentFromTrackingNumber?.id ?? '' },
  });
  const [showDamageModal, setShowDamageModal] = useState(false);
  const [showDoNotTrackModal, setShowDoNotTrackModal] = useState(false);
  const [damage, setDamage] = useState<{ category?: DamageRecordCategory; description?: string }>();
  const { copy } = useClipboard();

  const shipment = data?.shipmentFromTrackingNumber;
  const application = data?.shipmentFromTrackingNumber?.creditApplication;
  const applications = data?.shipmentFromTrackingNumber?.creditApplications;
  const mainApplication = application || applications?.[0];
  const applicationList = application ? [application] : applications;
  const showDoNotTrackButton =
    shipment?.shipmentType === ShipmentType.InboundShippingKit ||
    shipment?.shipmentType === ShipmentType.UserShipping ||
    shipment?.shipmentType === ShipmentType.Diy;

  const firstActiveItem = mainApplication?.items?.find(item => item?.active === true);

  const uid = `${trackingNumber}!${mainApplication?.id}@${firstActiveItem?.id}#${mainApplication?.pestoUser?.lastName}$${firstActiveItem?.type}`;

  const uidDescription = `Tracking Number ! Application ID @ 1st Item Id # Last Name $ Item Type`;

  const handleCopy = useCallback(
    (value: string) => {
      copy(value);
    },
    [copy],
  );

  const damageOptions = useMemo(() => {
    return Object.values(DamageRecordCategory).map(dd => {
      return { value: dd, label: humanize(dd) };
    });
  }, []);

  async function handleModalConfirm() {
    try {
      await addShippingDamageMutation({
        variables: {
          shippingId: shipment?.id ?? '',
          description: damage?.description ?? '',
          category: damage?.category as DamageRecordCategory,
        },
      });
      setShowDamageModal(false);
    } catch (e) {
      setShowDamageModal(false);
    }
  }

  const handleUpdateShipmentTracking = () => {
    updateShipmentTrackingMutation({
      variables: {
        shipmentId: shipment?.id ?? '',
        removeFromTracking: true,
      },
    }).finally(() => {
      setShowDoNotTrackModal(false);
    });
  };

  const carrierWebsite = getTrackingLink(shipment?.carrier, trackingNumber);

  if (loading) return <LoadingSpinner />;
  return (
    <MainWrapper className={'flex flex-col items-start gap-8'}>
      <BackButton />
      <Card className="bg-card w-full p-6">
        <Typography variant={'headerMedium'}>{`Shipment Details`}</Typography>
        <div className="flex w-full justify-between gap-4">
          <div>
            <div className={'flex items-center'}>
              <Typography variant={'caption'}>
                <strong>Id:</strong> {shipment?.id ?? ''}
              </Typography>
              <button className={'mx-4'} onClick={() => handleCopy(shipment?.id ?? '')}>
                <CopyIcon />
              </button>
            </div>
            <div className={'flex items-center gap-4'}>
              <Typography variant={'caption'}>
                <strong>Tracking Number:</strong> {shipment?.trackingNumber?.toUpperCase() ?? ''}
              </Typography>
              <button className={'mx-4'} onClick={() => handleCopy(shipment?.trackingNumber ?? '')}>
                <CopyIcon />
              </button>
            </div>
            <div className={'flex items-center gap-4 leading-8'}>
              <Typography variant={'caption'}>
                <strong>Cardholder:</strong> {String(mainApplication?.pestoUser?.isCardholder) ?? ''}
              </Typography>
            </div>
            <div className={'flex items-center gap-4'}>
              <Button color={'destructive'} onClick={() => setShowDamageModal(true)}>
                Report Damage
              </Button>
              {showDoNotTrackButton && (
                <Button
                  onClick={() => {
                    setShowDoNotTrackModal(true);
                  }}
                  disabled={shipment?.status === ShipmentStatus.Unknown}
                >
                  Do not track
                </Button>
              )}
            </div>
          </div>
          <div>
            <QrCode data={shipment?.qrCode ?? ''} size={70} />
          </div>
        </div>
      </Card>

      <div />

      <Card className="text-foreground bg-card w-full overflow-x-auto sm:rounded-lg">
        <table className="w-full text-left text-sm">
          <tbody>
            <tr className="border-b">
              <th scope="row" className="whitespace-nowrap p-4 font-medium">
                Shipping Status
              </th>
              <td className="p-4 text-end">{humanize(data?.shipmentFromTrackingNumber?.status ?? '')}</td>
            </tr>
            <tr className="border-b">
              <th scope="row" className="whitespace-nowrap p-4 font-medium">
                Shipping Type
              </th>
              <td className="p-4 text-end">{humanize(data?.shipmentFromTrackingNumber?.shipmentType ?? '')}</td>
            </tr>
            <tr className="border-b">
              <th scope="row" className="whitespace-nowrap p-4 font-medium">
                {shipment?.carrier?.toUpperCase()} Tracking Number
              </th>
              <td className="p-4 text-end">
                <div className={'flex items-center justify-end'}>
                  <a href={carrierWebsite} target={'_blank'} className="text-action hover:text-actionSoft mx-4">
                    <Typography variant={'caption'}>{trackingNumber}</Typography>
                  </a>
                  <QrCode data={trackingNumber} size={70} />
                </div>
              </td>
            </tr>
            {data?.shipmentFromTrackingNumber?.shipmentType === ShipmentType.InboundShippingKit && (
              <>
                <tr className="border-b">
                  <th scope="row" className="whitespace-nowrap p-4 font-medium">
                    Shipping Kit Sent
                  </th>
                  <td className="p-4 text-end">{data?.shipmentFromTrackingNumber?.shippingKitSent ? 'Yes' : 'No'}</td>
                </tr>
                <tr className="border-b">
                  <th scope="row" className="whitespace-nowrap p-4 font-medium">
                    Record Shipping Kit Sent
                  </th>
                  <td className="p-4 text-end">
                    <Checkbox
                      checked={data?.shipmentFromTrackingNumber?.shippingKitSent ?? false}
                      onChange={event => {
                        if (event.target) {
                          markShippingKitSent();
                        }
                      }}
                    />
                  </td>
                </tr>
              </>
            )}
            <tr className="border-b">
              <th scope="row" className="whitespace-nowrap p-4 font-medium">
                UID ( {uidDescription} )
              </th>
              <td className="p-4 text-end">
                <div className={'flex items-center justify-end'}>
                  <QrCode data={uid} size={70} />
                </div>
                {uid}
              </td>
            </tr>
          </tbody>
        </table>
      </Card>

      {application ? (
        <Typography variant={'subHeroSmall'}>{`Application`}</Typography>
      ) : (
        <Typography variant={'subHeroSmall'}>{`Associated Applications`}</Typography>
      )}

      <div className={'grid gap-4 md:grid-cols-[repeat(auto-fill,_minmax(_320px,_1fr))]'}>
        {applicationList?.map((application, index) => (
          <ApplicationCard key={application?.id} application={application as CreditApplication} index={index} />
        ))}
      </div>
      <Modal
        isOpen={showDamageModal}
        onClose={() => setShowDamageModal(false)}
        title={'Record Damaged Box'}
        onConfirm={handleModalConfirm}
        loading={loadingDamage}
        confirmProps={{ disabled: !damage }}
      >
        <DropdownInput
          options={damageOptions}
          label={'Select damage'}
          value={damage?.category}
          onChange={value => setDamage({ ...damage, category: value as DamageRecordCategory })}
        />
        <TextAreaField
          defaultValue={''}
          onChange={value => setDamage({ ...damage, description: value.target.value })}
          label={'Note'}
        />
      </Modal>
      <Modal
        isOpen={showDoNotTrackModal}
        onClose={() => setShowDoNotTrackModal(false)}
        title={'Do not track'}
        onConfirm={handleUpdateShipmentTracking}
        loading={loadingUpdateShipment}
      >
        <Typography variant={'body'}>
          Are you sure you want to mark this shipment as "Do not track"? This will prevent the shipment from being
          tracked.
        </Typography>
      </Modal>
    </MainWrapper>
  );
}
