import { Button, Typography } from '@pesto/ui';
import type { DataTableFilterField } from '@pesto/ui/components/DataTable/DataTable.types';
import { DataTableColumnHeader } from '@pesto/ui/components/DataTable/DataTableColumnHeader';
import type { ColumnDef, TableState } from '@tanstack/react-table';

import type { Partner } from '../../../__generated__/graphql/api';

const columnIdNames = {
  partnerId: 'id',
  name: 'name',
  cardProgram: 'cardProgram',
};

export const usePartnersListConfig = () => {
  const partnersFilterFields: DataTableFilterField<any>[] = [
    {
      label: 'Partner ID',
      value: columnIdNames.partnerId,
      placeholder: 'Partner ID search',
    },
    {
      label: 'Name',
      value: columnIdNames.name,
      placeholder: 'Name search',
    },
    {
      label: 'Card Program',
      value: columnIdNames.cardProgram,
      placeholder: 'Card Program search',
    },
  ];

  const getPartnersColumns = (handleRowClicked: (row: Partner) => void): ColumnDef<Partner>[] => [
    {
      id: 'view',
      header: 'Action',
      accessorKey: 'id',
      enablePinning: true,
      cell: row => (
        <Button onClick={() => handleRowClicked(row.cell.row.original)} variant={'outline'}>
          View
        </Button>
      ),
    },
    {
      id: columnIdNames.partnerId,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Partner ID" />,
      accessorFn: row => row.id,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {row.cell.getValue() as string}
        </Typography>
      ),
    },
    {
      id: columnIdNames.name,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Name" />,
      accessorFn: row => row.name,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {row.cell.getValue() as string}
        </Typography>
      ),
    },
    {
      id: columnIdNames.cardProgram,
      header: ({ column }) => <DataTableColumnHeader column={column} title="Card Program" />,
      accessorFn: row => row.cardProgram,
      cell: row => (
        <Typography variant={'body'} className={'w-auto text-nowrap'}>
          {row.cell.getValue() as string}
        </Typography>
      ),
    },
  ];

  const initialTableState: Partial<TableState> = {
    columnPinning: { left: ['view'] },
    // sorting: [{ id: columnIdNames.insertedAt, desc: true }],
  };

  return { partnersFilterFields, getPartnersColumns, initialTableState };
};
