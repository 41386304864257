import { PaperClipIcon } from '@heroicons/react/20/solid';
import { Button, Card } from '@pesto/ui';
import { uploadLegalFile } from '@pesto/utils/src/upload-file/uploadLegalFile';
import { useRef, useState } from 'react';

import { LEGAL_LINK } from '../constants/legalLinks';

interface LinkRowProps {
  name: string;
  fileName: string;
}

export const LinkRow = (props: LinkRowProps) => {
  const { name, fileName } = props;
  const fileHref = LEGAL_LINK + fileName;
  const inputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const changeHandler = (event: any, fileName: string) => {
    setIsLoading(true);
    uploadLegalFile(event.target.files[0], fileName)
      .then(res => {
        console.log('File uploaded successfully', res);
      })
      .catch(err => {
        console.error('Error uploading file', err);
      })
      .finally(() => {
        setIsLoading(false);
        if (inputRef?.current) {
          inputRef.current.value = '';
        }
      });
  };
  const handleUploadButtonClick = (e: any) => {
    e.preventDefault();
    inputRef?.current?.click();
  };

  return (
    <Card key={name + 'files-legal'} className="bg-card overflow-hidden shadow">
      <div className="flex flex-col justify-between gap-4 px-4 py-5 sm:flex-row sm:items-center sm:p-6">
        <div className="flex gap-2">
          <PaperClipIcon className="text-foreground h-5 w-5 flex-shrink-0" aria-hidden="true" />
          <a
            href={fileHref}
            target={'_blank'}
            className="hover:text-foreground/50 text-foreground truncate text-sm font-medium"
          >
            {name}
          </a>
        </div>
        <div className="flex gap-2">
          <Button color={'secondary'} onClick={() => window.open(fileHref, '_blank')}>
            View
          </Button>
          <input
            type="file"
            ref={inputRef}
            name="file"
            accept=".pdf"
            multiple={false}
            onChange={e => changeHandler(e, fileName)}
            style={{ display: 'none' }}
          />
          <Button type="button" onClick={(e: any) => handleUploadButtonClick(e)} disabled={isLoading}>
            {isLoading && (
              <svg
                className="text-primary -ml-1 mr-3 h-5 w-5 animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
              </svg>
            )}
            Upload New
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-2 px-4 pb-5">
        <div className="text-primary/60 truncate text-sm">{fileName}</div>
        <div className="text-primary/60 text-sm">{fileHref}</div>
      </div>
    </Card>
  );
};
