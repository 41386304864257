import { Card } from '@pesto/ui';
import { formatDate } from '@pesto/utils';

interface InactiveItemsPreviewProps {
  itemType: string;
  insertedAt: string;
  creditValue?: number | null;
}

export const InactiveItemsPreview = (props: InactiveItemsPreviewProps) => {
  const { itemType, insertedAt, creditValue } = props;
  return (
    <Card className="bg-muted rounded-lg p-4">
      <dt className="text-foreground text-lg font-semibold">Type: {itemType}</dt>
      <dt className="text-foreground text-md font-medium">Inserted: {formatDate(insertedAt)}</dt>
      {creditValue != null && <dt className="text-foreground text-md font-medium">Credit Value: ${creditValue}</dt>}
      <div className="mt-2 inline-flex items-center justify-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-800">
        Inactive
      </div>
    </Card>
  );
};
