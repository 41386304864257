import { useListQuery } from '@pesto/hooks';
import { Button } from '@pesto/ui';
import { SmartTable } from '@pesto/ui/features/SmartTable/SmartTable';
import { useNavigate } from 'react-router-dom';

import type { Partner } from '../../__generated__/graphql/api';
import { useListPartnersQuery } from '../../__generated__/graphql/api';
// Ensure this query hook exists
import { routes } from '../../constants/routes';

import { usePartnersListConfig } from './hooks/usePartnersListConfig';

export const Partners = () => {
  const navigate = useNavigate();
  const { data, loadingOrReFetching } = useListQuery({
    queryHook: useListPartnersQuery,
  });
  const { partnersFilterFields, getPartnersColumns, initialTableState } = usePartnersListConfig();

  const rows = data?.partners || [];

  const handleRowClicked = (row: Partner) => {
    navigate(`${routes.partners}/${row?.id}`);
  };
  const handleAddPartner = () => {
    navigate(`${routes.partners}/add`);
  };
  const columns = getPartnersColumns(handleRowClicked);

  return (
    <div>
      <div className="mb-4 flex items-center justify-between">
        <h1 className="text-xl font-bold">Partners</h1>
        <Button onClick={handleAddPartner} variant="primary">
          Add Partner
        </Button>
      </div>
      <SmartTable
        title="Partners"
        rows={rows}
        columns={columns}
        filterFields={partnersFilterFields}
        loading={loadingOrReFetching}
        initialState={initialTableState}
      />
    </div>
  );
};
