import { useQuery, useMutation } from '@apollo/client';
import { Button, Input, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@pesto/ui';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { GetPartnerByIdDocument, UpdatePartnerDocument, CollateralType } from '../../__generated__/graphql/api';
import { routes } from '../../constants/routes';

export const UpdatePartner = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { data, loading: queryLoading } = useQuery(GetPartnerByIdDocument, { variables: { id: id! } });
  const [updatePartner, { loading: mutationLoading, error }] = useMutation(UpdatePartnerDocument);
  const [formData, setFormData] = useState({
    name: data?.partner?.name || '',
    cardProgram: data?.partner?.cardProgram || '',
    collateralType: data?.partner?.collateralType || CollateralType.NoneSelected,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await updatePartner({ variables: { id: id!, input: formData } });
      navigate(`${routes.partners}/${id}`);
    } catch (err) {
      console.error(err);
    }
  };

  if (queryLoading) return <p>Loading...</p>;

  return (
    <div className="p-4">
      <h1 className="mb-4 text-xl font-bold">Update Partner</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <Input
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="mt-1 block w-full"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="cardProgram" className="block text-sm font-medium text-gray-700">
            Card Program
          </label>
          <Input
            id="cardProgram"
            name="cardProgram"
            value={formData.cardProgram}
            onChange={handleChange}
            required
            className="mt-1 block w-full"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="collateralType" className="block text-sm font-medium text-gray-700">
            Collateral Type
          </label>
          <Select
            value={formData.collateralType}
            onValueChange={(value: CollateralType) => setFormData({ ...formData, collateralType: value })}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select collateral type" />
            </SelectTrigger>
            <SelectContent>
              {Object.values(CollateralType).map(type => (
                <SelectItem key={type} value={type}>
                  {type.replace(/_/g, ' ')}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <Button type="submit" variant="primary" loading={mutationLoading}>
          Update Partner
        </Button>
        <Button variant="outline" onClick={() => window.history.back()}>
          Cancel
        </Button>
        {error && <p className="mt-2 text-sm text-red-600">{error.message}</p>}
      </form>
    </div>
  );
};
