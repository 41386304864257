import { useQuery } from '@apollo/client';
import { Typography, Card, Button } from '@pesto/ui';
import { useParams, useNavigate } from 'react-router-dom';

import type { GetPartnerByIdQuery, GetPartnerByIdQueryVariables } from '../../__generated__/graphql/api';
import { GetPartnerByIdDocument } from '../../__generated__/graphql/api';

export const PartnerDetails = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { data, loading, error } = useQuery<GetPartnerByIdQuery, GetPartnerByIdQueryVariables>(GetPartnerByIdDocument, {
    variables: { id: id! },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const partner = data?.partner;

  if (!partner) return <p>Partner not found</p>;

  const handleAddUser = () => {
    navigate(`/partners/${id}/add-user`);
  };

  const handleEditPartner = () => {
    navigate(`/partners/${id}/edit`);
  };

  const handleEditUser = (userId: string) => {
    navigate(`/partners/${id}/users/${userId}/edit`);
  };

  return (
    <>
      <div className={'flex flex-col gap-6'}>
        <Typography variant={'headerLarge'}>Partner Details</Typography>
      </div>
      <div className="p-4">
        <Card>
          <Typography variant="headerLarge">{partner.name}</Typography>
          <Typography variant="body">ID: {partner.id}</Typography>
          <Typography variant="body">Card Program: {partner.cardProgram}</Typography>
          <Typography variant="body">
            Collateral Type: {partner.collateralType?.replace(/_/g, ' ') ?? 'None Selected'}
          </Typography>
          <Button variant="primary" onClick={handleEditPartner}>
            Edit Partner
          </Button>
          <Typography variant="headerMedium" className="mt-4">
            Users
          </Typography>
          {partner.users?.length ? (
            <ul>
              {partner.users.map(user => (
                <li key={user?.id ?? 'unknown'}>
                  <Typography variant="body">
                    {user?.firstName ?? 'Unknown'} {user?.lastName ?? 'User'} - {user?.email ?? 'No email'}
                  </Typography>
                  <Button variant="outline" onClick={() => handleEditUser(user?.id ?? '')}>
                    Edit User
                  </Button>
                </li>
              ))}
            </ul>
          ) : (
            <Typography variant="body">No users found</Typography>
          )}
        </Card>
        <div className="mt-4 flex gap-4">
          <Button variant="outline" onClick={() => window.history.back()}>
            Back
          </Button>
          <Button variant="primary" onClick={handleAddUser}>
            Add User
          </Button>
        </div>
      </div>
    </>
  );
};
